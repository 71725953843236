import React from 'react';
import './css/StartPageWrapper.scss'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";

import Quote from './Quote';
import Newest from './Newest';
import PostList from './../PostList';

import axios from 'axios';

class StartPageWrapper extends React.Component {
  state = {
    articles: {},
  }
  componentDidMount(){
    axios.get('/api/blog/articles.php?limit=7')
    .then(({data}) => {
      if(data.status){
        this.setState({articles: data.response})
      }
    }, (error) => {
      console.log(error);
    });
  }
  render(){
    return (
      <div className="startPageWrapper">

        <MetaTags>
          <title>Blog();</title>
          <meta property="og:title"       content="Blog();" />
          <meta property="og:description" content="Nowości oraz ciekawostki z branży IT z naciskiem na programowanie, znajdziesz tu też kilka serii poradników. Wejdź i przekonaj się czy warto, może zostaniesz na dłużej."/>
          <meta
            name="description"
            content="Nowości oraz ciekawostki z branży IT z naciskiem na programowanie, znajdziesz tu też kilka serii poradników. Wejdź i przekonaj się czy warto, może zostaniesz na dłużej."
          />
          <meta property="og:image" content="https://api.dziuda.it/img/glasses.jpg" />
          <meta property="og:secure_url" content="https://api.dziuda.it/img/glasses.jpg" />
        </MetaTags>

        <div className="landing">
          <div className="startPageSide quoteSide">
            <Quote/>
          </div>
          <div className="startPageSide">
            <Newest article={this.state.articles[0]}/>
          </div>
        </div>
        <div className="allPostsWrapper">
          <PostList articles={this.state.articles}/>
        </div>
        <div className="showAll">
          <Link to={`/new`}>
            <div className="buttonCon">
              <div className="simpleButton-inverted">
                <div className="textContainer">
                  Pokaż wszystkie
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default StartPageWrapper;
