import React from 'react';

import './css/ArticleView.scss';
import './css/darkGist.scss';

class ArticleView extends React.Component{
  getBackgroundUrl(){
    const imgUrl = this.props.article.img_url.split("main.jpg")[0];

    return `url("${imgUrl}raw.jpg")`;
  }
  render(){
    return (
      <div className="articleView">
        <div className="titleContainer">
          <div className="titleBackground" style={{backgroundImage: this.getBackgroundUrl()}}>
            <div className="title">
              <header>
                {this.props.article.title}
              </header>
            </div>
          </div>
          <div className="introContainer">
            <section>
              {this.props.article.intro}
            </section>

          </div>
        </div>
        <div className="contentContainer">
          <section>
            {this.props.article.body}
          </section>
        </div>
      </div>
    );
  }
}

export default ArticleView;
