import React from 'react';
import './css/SideMenu.scss'
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SideMenu = (props) => {
  return (
    <div className="sideMenu">
      <div className="closeMenu" onClick={props.hideMenu}>
        <div className="iconCon">
          <FontAwesomeIcon icon="times" />
        </div>
      </div>

      <div className="sideMenuSpacer"></div>

      <nav>
        <div className="buttonsWrapper">
          <Link to={`/new`} onClick={props.hideMenu}>
            <div className="simpleButton-inverted">
              <div className="textContainer">
                NAJNOWSZE
              </div>
            </div>
          </Link>
          <Link to={`/new/category`} onClick={props.hideMenu}>
            <div className="simpleButton-inverted">
              <div className="textContainer">
                KATEGORIE
              </div>
            </div>
          </Link>
          <Link to={`/contact`} onClick={props.hideMenu}>
            <div className="simpleButton-inverted">
              <div className="textContainer">
                KONTAKT
              </div>
            </div>
          </Link>
        </div>

        <div className="sideMenuSpacer"></div>

        <div className="buttonsWrapper">
          <a href="https://dziuda.it" onClick={props.hideMenu}>
            <div className="simpleButton-inverted">
              <div className="textContainer">
                PORTFOLIO
              </div>
            </div>
          </a>
          <a href="https://www.facebook.com/Hubert-Dziuda-107570980920770" onClick={props.hideMenu}>
            <div className="simpleButton-inverted">
              <div className="textContainer">
                FACEBOOK
              </div>
            </div>
          </a>
          <a href="https://github.com/Savuelo" onClick={props.hideMenu}> 
            <div className="simpleButton-inverted">
              <div className="textContainer">
                GITHUB
              </div>
            </div>
          </a>

          <div className="sideMenuSpacer"></div>
        </div>
      </nav>
    </div>
  );
}

export default SideMenu;
