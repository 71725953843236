import React from 'react';
import './css/Newest.scss';

import Post from '../Post';

const Newest = (props) => {
  return (
    <div className="newest">
      <div className="sectionTitle">
        <h3>Najnowszy wpis:</h3>
      </div>
      <Post article={props.article}/>
    </div>
  );
}

export default Newest;
