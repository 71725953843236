import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import './css/CategorySelect.scss'
class CategorySelect extends Component {
  render() {
    const keyword = this.props.match.params.keyword;
    return (
      <div className="categorySelect">
          <div className={`categorySelectItem ${keyword === "programming" ? "selectedCategory" : ""}`}>
            <Link to={`/new/category/programming`}>
              <span>PROGRAMOWANIE</span>
            </Link>
          </div>
          <div className={`categorySelectItem ${keyword === "news" ? "selectedCategory" : ""}`}>
            <Link to={`/new/category/news`}>
                <span>NEWSY</span>
            </Link>
          </div>
          <div className={`categorySelectItem ${keyword === "casual" ? "selectedCategory" : ""}`}>
            <Link to={`/new/category/casual`}>
                <span>RÓŻNE</span>
            </Link>
          </div>
      </div>
    );
  }
}

export default withRouter(CategorySelect);
