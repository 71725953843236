import React from 'react';
import CKEditor from 'ckeditor4-react';

class Editor extends React.Component{
  render(){
    return (
      <div>
        <CKEditor
          data="<p>Hello from CKEditor 4!</p>"
        />
      </div>
    );
  }
}

export default Editor;

