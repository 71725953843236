import React, { Component } from 'react';
import Post from './Post';

import './css/PostList.scss';
class PostList extends Component {
  createPosts = () => {

    try{
      let arr = []
      if(!this.props.articles.length)
        return;

      for(const el of this.props.articles){
        arr.push(
          <div className="postTile col-lg-4 col-sm-6" key={el.id}>
            <Post article={el} edu={true}/>
          </div>
        )
      }
    if(!this.props.showAll)
        arr[0] = null;
      return arr;
    }
    catch{
      return [];
    }
  }
  render() {
    let posts = this.createPosts();
    return (
      <div className="postList">
        <div className="container-fluid">
          <div className="row">
            <div className="sectionTitle col-sm-12 mb-2">
              <h3>Pozostałe:</h3>
            </div>
          </div>
          <div className="row">
            { posts }
          </div>
        </div>
      </div>
    );
  }
}

export default PostList;
