import React from 'react';
import './css/TopBar.scss';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TopBar = (props) => {
  return (
    <div className={`topBar`}>
      <nav>
        <div className="sideMenuButton" onClick={props.handleMenu}>
          <div className="iconContainer">
            <FontAwesomeIcon icon="bars" />
          </div>
        </div>
        <div className="blogName">
          <header>
            <Link to={`/`}><span>Blog();</span></Link>
          </header>
        </div>
        <div className="options">
          <div className="optionsWrapper">
            <div className="simpleButton">
              <Link to={`/new`}>
                <div className="textContainer">
                  WPISY
                </div>
              </Link>
            </div>
            <div className="simpleButton">
              <Link to={`/new/category/programming`}>
                <div className="textContainer">
                  KATEGORIE
                </div>
              </Link>
            </div>
            <div className="simpleButton-inverted">
              <Link to={`/contact`}>
                <div className="textContainer">
                  KONTAKT
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="iconsSection">
          <div className="socialIcons">
            <a href="https://dziuda.it">
                <FontAwesomeIcon icon="home" />
                <span className="socialIconText">Portfolio</span>
            </a>
            {/* <a href="google.pl">
              <FontAwesomeIcon icon={['fab', 'facebook-square']} />
            </a> */}
            <a href="https://www.facebook.com/Hubert-Dziuda-107570980920770">
              <FontAwesomeIcon icon={['fab', 'facebook-square']} />
              <span className="socialIconText">Facebook</span>
            </a>
            <a href="https://github.com/Savuelo">
              <FontAwesomeIcon icon={['fab', 'github-square']} />
              <span className="socialIconText">GitHub</span>
            </a>
          </div>
          <div className="lighUpButton" >
            <div className="lightWrapper">
              <div  className={`${props.light ? "showSun" : "showMoon"} showSpace`}
                    onClick={props.handleLight}>
                <div className="lightIcon moonIcon">
                  <FontAwesomeIcon icon="moon" />
                </div>
                <div className="lightIcon sunIcon">
                  <FontAwesomeIcon icon="sun" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default TopBar;
