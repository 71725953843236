import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import {
  TransitionGroup,
  CSSTransition
} from 'react-transition-group';
import Cookies from 'js-cookie';
import axios from 'axios'

import './css/App.scss';
import './css/App-Dark.scss';
import './css/assets.scss';

import TopBar from './navigation/TopBar';
import SideMenu from './navigation/SideMenu';
import Footer from './navigation/Footer';
import CookiesPopUp from './navigation/CookiesPopUp';

import StartPageWrapper from './view/startPage/StartPageWrapper'
import Article from './view/article/Article'
import New from './view/new/New'
import Contact from './view/contact/Contact'
import Editor from './view/editor/Editor'

class App extends React.Component {
  constructor(props){
    super(props);
    
    axios.defaults.baseURL = 'https://api.dziuda.it'
    // axios.defaults.baseURL = 'http://localhost'

    let tempLight = false;
    Cookies.get('light') === undefined ? tempLight = "true" : tempLight = Cookies.get('light');
    this.state = {
      light: (tempLight === "true"),
      showMenu: false,
    }
  }
  handleMenu = () => {
    this.setState({showMenu: !this.state.showMenu});
  }
  handleLight = () => {
    if(this.state.light){
      this.setState({light: false});
      Cookies.set('light', false, { expires: 365 });
    }else{
      this.setState({light: true});
      Cookies.set('light', true, { expires: 365 });
    }
  }
  hideMenu = () => {
    if(this.state.showMenu){
      this.setState({showMenu: false});
    }
  }
  isCookiesAccepted = () => {
    return Cookies.get('accepted');
  }
  acceptCookie = () => {
    Cookies.set('accepted', true, { expires: 365 });
    this.setState({ state: this.state });
  }
  render() {
    return (
      <Router>
        <Route render={( { location }) => (
          <div className= {`${!this.state.light ? "app-dark semi-dark-gist" : ""} ${this.state.showMenu ? "app-showMenu" : ""} app`}>
          <TopBar handleLight={this.handleLight} 
                  handleMenu={this.handleMenu} 
                  light={this.state.light}/>
          <SideMenu hideMenu={this.hideMenu} />
          <div className="view" onClick={this.hideMenu}>
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                classNames="my-node"
                timeout={210}
              >
              <Switch location={location}>
                  <Route path="/article/:articleId">
                    <Article />
                  </Route>
                  <Route path="/new/:option?/:keyword?">
                    <New/>
                  </Route>
                  <Route path="/contact">
                    <Contact/>
                  </Route>
                  <Route path="/editor">
                    <Editor />
                  </Route>
                  <Route path="/">
                    <StartPageWrapper/>
                  </Route>
              </Switch>
              </CSSTransition>
            </TransitionGroup>
          </div>
          <Footer />

          {this.isCookiesAccepted() ? "" : <CookiesPopUp acceptCookie={this.acceptCookie} />}
        </div>
        )}/>

      </Router>
    );
  }
}
export default App;
