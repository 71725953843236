import React from 'react';
import './css/Post.scss'
import { Link } from "react-router-dom";
import Parser from 'html-react-parser';

const Post = (props) => {
  let link = "0/0";
  let imgUrl = "";
  let postIntro = "";
  let postDate = "";
  if(props.article ){
    let linkTitle = props.article.title.replace(/ /g, "_")
    link = `${props.article.id}/${linkTitle}`

    postDate = props.article.date;
    postIntro = props.article.intro.split(" ").splice(0,24).join(" ") + "...";

    imgUrl = props.article.img_url.split(".jpg")[0];
  }
  return (
    <div className={`post`}>
      <div className="postImage">
        <Link to={`/article/${link}`}>
          <div className="postImageContainer">
            <picture>
              <source type="image/webp" srcSet={imgUrl+'-sm.webp'} />
              <source type="image/jpeg" srcSet={imgUrl+'-sm.jpg'} />
              <img src={imgUrl+'-sm.jpg'} alt="Obrazek artykułu" />
            </picture>
          </div>
        </Link>

        <div className="postDate">
          <span>{ postDate}</span>
        </div>
      </div>
      <div className="postDescription">
        <div className="postTitle">
          {props.article ? props.article.title : "Lorem ipsum dolor sit amet."}
        </div>
        <div className="postIntro">
         {Parser(props.article ? postIntro : "Space <br/> Space")}
        </div>
        <div className="readMore">
        <Link to={`/article/${link}`}>Czytaj więcej...</Link>
        </div>
      </div>
    </div>
  );
}

export default Post;
