import React from 'react';
import { Link } from "react-router-dom";
import './css/Footer.scss'
const Footer = () => {
  return (
    <div className="footer">
      <footer>
        <div className="footerContainer">
          <div className="description">
            Hej<br />
            Dzięki że jesteś<br />
            <div className="thanks">
            Trzymaj się ❤
            </div>
          </div>
          <div className="navigation">
            <div className="row">
              <div className="footerNavItem">
                <Link to={`/new/category/programming`}>
                  PROGRAMOWANIE
                </Link>
              </div>
              <div className="footerNavItem">
                <Link to={`/new/category/news`}>
                  NEWSY
                </Link>
              </div>
              <div className="footerNavItem">
                <Link to={`/new/category/casual`}>
                  RÓZNE
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="footerNavItem">
                <a href="https://dziuda.it">
                  PORTFOLIO
                </a>
              </div>
              <div className="footerNavItem">
                <a href="https://www.facebook.com/Hubert-Dziuda-107570980920770">
                  FACEBOOK
                </a>
              </div>
              <div className="footerNavItem">
                <a href="https://github.com/Savuelo">
                  GITHUB
                </a>
              </div>
            </div>
          </div> 
        </div>
        <div className="copy">
          Hubert Dziuda &copy; 2020
        </div>
      </footer>
    </div>
  );
}

export default Footer;
