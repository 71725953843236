import React from 'react';
import './css/RecommendedPost.scss'
import axios from 'axios'
import Post from '../Post'

class RecommendedPost extends React.Component{
  state = {
    articles: {},
    show: true,
  }
  componentDidMount(){
    const id = this.props.articleId;
    axios.get(`/api/blog/articles.php?id=${id}&partial=true&limit=4&recommended=true`)
    .then(({ data }) => {
      if(data.status){
        this.setState({articles: data.response})
      }
    }, (error) => {
      this.setState({show: false});
    });
  }

  createPosts = () => {
    let arr = []
    if(!this.state.articles.length)
      return;

    for(const el of this.state.articles){
      arr.push(
        <div className="sideTile col-lg-6 col-md-6" key={el.id}>
          <Post article={el} edu={true}/>
        </div>
      )
    }
    return arr;
  }

  render(){
    if(!this.state.show)
      return (
        <div className="emptyPost"></div>
      );
      
    const posts = this.createPosts();
    return (
      <div className="recommendedPost">
        <aside>
          <div className="others">
            Inne wpisy:
          </div>
          <div className="container">
            <div className="row">
              { posts }
            </div>
          </div>
        </aside>
      </div>
    );
  }
}

export default RecommendedPost;
