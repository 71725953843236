import React, { Component } from 'react';
import { withRouter } from "react-router";
import MetaTags from 'react-meta-tags';

import PostList from './../PostList';
import CategorySelect from './CategorySelect';
import './css/New.scss'

import axios from 'axios'
class New extends Component {
  state = {
    articles: {},
    showCategories: false,
  }
  componentDidMount(){
    if(this.props.match.params.option){
      this.setState({showCategories: true});
      if(this.props.match.params.keyword){
        this.loadPostsByCategory(this.props.match.params.keyword);
      }
    }else{
      this.loadNewestPosts()
    }
  }
  loadPostsByCategory = (category) => {
    axios.get(`/api/blog/articles.php?partial=true&category=${category}`)
    .then(({data}) => {
      if(data.status){
        this.setState({articles: data.response});
      }
    }, (error) => {
      console.log(error);
    });
  }
  loadNewestPosts = () => {
    axios.get(`/api/blog/articles.php?partial=true`)
    .then(({data}) => {
      if(data.status){
        this.setState({articles: data.response})
        
      }
    }, (error) => {
      console.log(error);
    });
  }
  render() {
    return (
      <div className="new">
          <MetaTags>
            <title>Blog(); Wpisy</title>
            <meta property="og:title"       content="Blog(); Wpisy" />
            <meta property="og:description" content="Nowości oraz ciekawostki z branży IT z naciskiem na programowanie, znajdziesz tu też kilka serii poradników. Wejdź i przekonaj się czy warto, może zostaniesz na dłużej."/>
            <meta
              name="description"
              content="Nowości oraz ciekawostki z branży IT z naciskiem na programowanie, znajdziesz tu też kilka serii poradników. Wejdź i przekonaj się czy warto, może zostaniesz na dłużej."
            />
            <meta property="og:image" content="https://api.dziuda.it/img/glasses.jpg" />
            <meta property="og:secure_url" content="https://api.dziuda.it/img/glasses.jpg" />
          </MetaTags>

        { this.state.showCategories ? <CategorySelect /> : ""}
        <PostList articles={this.state.articles} showAll={true}/>
      </div>
    );
  }
}

export default withRouter(New);
