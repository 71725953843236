import React, { Component } from 'react';
import './css/IndexMenu.scss';

class IndexMenu extends Component {
  state = {
  }
  componentDidMount(){
    this.setState({state: this.state});
  }
  renderItems(){
    let arr = [];
    let i = 1;
    for(const element of this.props.index){
      arr.push((
        <div className="indexItem" onClick= {(()=>{
          const y = element.current.getBoundingClientRect().top + window.pageYOffset + -77;
          window.scrollTo({top: y, behavior: 'smooth'});
        })} key={`indexMenu-${i}`}>
          {element.current.innerText}
        </div>
      ))
      i++;
    }

    return arr;
  }
  render() {
    return (
      <div className="indexMenu">
        <div className="indexItems">
          <div className="indexTitle">
            Spis treści
          </div>

          <div className="indexItem" onClick= {(()=>{
                window.scrollTo({top: 0, behavior: 'smooth'});
              })} key={`indexMenu-start`}>

              Start
          </div>
          {this.props.index[0].current ?
            this.renderItems() :
            ""
          }
        </div>
      </div>
    )
  }
}

export default IndexMenu;
