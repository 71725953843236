import React from 'react';
import { withRouter } from "react-router";

import axios from 'axios';
import MetaTags from 'react-meta-tags';
import reactStringReplace from 'react-string-replace';
import Gist from 'super-react-gist';
import Parser from 'html-react-parser';

import IndexMenu from './IndexMenu';
import ArticleView from './ArticleView';
import RecommendedPost from './RecommendedPost';
import './css/Article.scss';

class Article extends React.Component {
  state = {
    articleBody: null,
  }
  //Get raw innerHtml from text element 
  getRawHtmlInnerText(element){
    let div = document.createElement("div");
    div.innerHTML = element;
    return  div.innerText;
  }
  //Replece gist temponary container from raw data actuals gists
  prepareArticle(responseArticle){
    let tempArticleContent  = reactStringReplace(responseArticle.content, /{(.*)}/, (match, i) => {
      const parsedMatch = match.split(',');
      if(parsedMatch[0] === 'gist' && parsedMatch[1]){
        return(<div className="gistContainer"><Gist url={parsedMatch[1]}/></div>)
      }else{
        return(<div></div>)
      }
    })

    responseArticle.content = tempArticleContent;
    return responseArticle;
  }
  //Add Gists into body
  addGists(body, gists){
    let gistId = 0;

    return Parser(body,{
      replace: domNode => {
        if(domNode.attribs && domNode.attribs.class === 'gist') {
          return gists[gistId++];
        }
      }}
    );
  }
  //Return ready to display object
  createArticleObject(rawArticle){
    let articleBody = "";
    let gists = [];
    const arr = rawArticle.content;

    //Joins together all elements of type string, to exclude Gist Components
    for(const element of arr){
      if(typeof element === "string")
        articleBody+=element;
      else
        gists.push(element)
    }

    //Replace div placeholder with actual gist or h1element in correct place
    let parsedBody = this.addGists(articleBody, gists);

    //Create refs to Headers
    let ids = 0;
    let indexes = [];
    parsedBody = parsedBody.map((thing) =>{
      if(thing.type === "h1" || thing.type === "h2"){
        indexes[ids] = React.createRef();
        return (<div key={`elementOfContent-${ids}`} ref={indexes[ids++]}>{thing}</div>);
      }else{
        return thing;
      }
    })

    rawArticle.body = parsedBody;
    rawArticle.index = indexes;

    rawArticle.category = rawArticle.category === "programming" ? "Programowanie":
                          rawArticle.category === "news" ? "Newsy" :
                          "Różne";

    return rawArticle;
  }
  componentDidMount(){
    const id = this.props.match.params.articleId;
    
    axios.get(`/api/blog/articles.php?id=${id}`)
    .then(({ data }) => {
      if(data.status){
        let responseArticle = data.response[0];

        //Strip HTML Tags from string of article intro
        responseArticle.intro = this.getRawHtmlInnerText(responseArticle.intro);

        const articleObject = this.createArticleObject(this.prepareArticle(responseArticle))
        this.setState({articleBody: articleObject})
      }else{
        this.props.history.push('/');
      }
    }, () => {
      this.props.history.push('/');
    });
  }
  render(){
    return (
      <div className="article">
        <div className="container-fluid">
        {this.state.articleBody !== null ? (
          <div className="row justify-content-center">
              <MetaTags>
                  <title>{this.state.articleBody.title}</title>
                  <meta name="description" content={this.state.articleBody.intro} />
                  <meta property="og:title"       content={this.state.articleBody.title} />
                  <meta property="og:description" content={this.state.articleBody.intro}/>
                  <meta property="og:image"       content={`https://api.dziuda.it${this.state.articleBody.img_url}`} />
                  <meta property="og:secure_url"  content={`https://api.dziuda.it${this.state.articleBody.img_url}`} />
                </MetaTags>
              


              <div className="indexMenuContainer col-xl-2 col-lg-3 col-md-12 d-lg-block d-none">
                <div className="additionalInfo d-xl-none d-lg-block">
                  { this.state.articleBody.category } <br />
                  {this.state.articleBody.date}
                </div>
                <IndexMenu index={this.state.articleBody.index}/>
              </div>
              <div className="mainContent col-xl-8 col-lg-9 col-md-12">
                <ArticleView article={this.state.articleBody}/>
                <RecommendedPost articleId={this.props.match.params.articleId} />
              </div>
              <div className="sideContent col-xl-2 d-xl-block d-none">
                <div className="additionalInfo">
                  { this.state.articleBody.category } <br />
                  {this.state.articleBody.date}
                </div>
              </div>

          </div>
        ) : "" }
        </div>
      </div>
    );
  }
}

export default withRouter(Article);