import React from 'react';
import './css/Quote.scss';

import { gsap } from 'gsap';
class Quote extends React.Component{
  special = [];
  con = null;
  border = null;

  componentDidMount() {
    // const tl = new TimelineMax()
    gsap.fromTo(this.con, {opacity: 0}, {duration: 0.4, opacity: 1})

    gsap.to(this.special, {duration: 0.2, color: "#099ef5"})
    gsap.to(this.border, {duration: 0.3, transform: "scaleY(1)", delay: 0.1})
  }
  render(){
    return (
      <div className="quote">
        <div className="quoteContainer" ref={d => this.con = d}>
          <div className="quoteBorder" ref={d => this.border = d}></div>
          <header>
            &emsp;
            <span className="specialText" ref={d => this.special[0] = d}>Jedyną</span> 
            <span> osobą od której powinieneś być lepszy, jesteś </span>
            <span className="specialText" ref={d => this.special[1] = d}>T</span> 
            <span className="specialText" ref={d => this.special[2] = d} 
                  style={{textDecoration: 'none'}}>
                    y
            </span>
            <span> z dnia wczorajszego.</span>
          </header>
        </div>
      </div>
    );
  }
}

export default Quote;
