import React from 'react';
import './css/Contact.scss';
import MetaTags from 'react-meta-tags';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Contact = () => {
  return (
    <div className="contact">
      <MetaTags>
        <title>Blog(); Kontakt</title>
        <meta property="og:title"       content="Blog(); Kontakt" />
        <meta property="og:description" content="Nowości oraz ciekawostki z branży IT z naciskiem na programowanie, znajdziesz tu też kilka serii poradników. Wejdź i przekonaj się czy warto, może zostaniesz na dłużej."/>
        <meta
          name="description"
          content="Nowości oraz ciekawostki z branży IT z naciskiem na programowanie, znajdziesz tu też kilka serii poradników. Wejdź i przekonaj się czy warto, może zostaniesz na dłużej."
        />
        <meta property="og:image" content="https://api.dziuda.it/img/glasses.jpg" />
        <meta property="og:secure_url" content="https://api.dziuda.it/img/glasses.jpg" />
      </MetaTags>

      <div className="info">
        <div className="infoDesc">
          <span>
            Jeśli chcesz się ze mną skontaktować napisz Mail'a na:
          </span>
        </div>
        <div className="mailInfo">
          <span>hubert@dziuda.it</span>
        </div>
      </div>
      <div className="info">
        <div className="infoDesc">
          <span>
            Lub odezwij się do mnie na Facebooku: 
          </span>
        </div>
        <div className="fbInfo">
          <a href="https://www.facebook.com/Hubert-Dziuda-107570980920770">
            <FontAwesomeIcon icon={['fab', 'facebook-square']} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
