import React from 'react';
import './css/CookiesPopUp.scss'
const Cookies = (props) => {
  return (
    <div className="cookiesPopUp">
      <div className="cokkiesInfo">
        Ta strona korzysta z ciasteczek (z ang. Cookies) by zagwarantować komfort użytkowania. Możesz wyłączyć obsługę ciasteczek w ustawieniach swojej przeglądarki. Dalsze korzystanie z tej witryny oznacza że zgadzasz się na ich wykorzystanie.
      </div>
      <div className="cookiesOptions">
        <div className="cookieCancel">
          <a href="https://google.com">Nie wyrażam zgody</a>
        </div>
        <div className="cookieOk" onClick={props.acceptCookie}>
          Ok.
        </div>
      </div>
    </div>
  );
}

export default Cookies;
